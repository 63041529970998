@font-face {
  font-family: "Ethnocentric";
  src: url(../font/ethnocentric.otf);
}

@font-face {
  font-family: "Digital-Regular";
  src: url(../font/digital-regular.otf);
}

:root {
  --primary: #ee8700;
  --secondary: #3d3d45;
  --background: #faf9ff;
}

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

body {
  font-family: "Comfortaa", cursive !important;
  font-weight: 500;
  background-color: var(--background) !important;
}

input:focus,
button:focus {
  outline: none !important;
}

input::placeholder,
textarea::placeholder {
  color: #bfbfbf !important;
}

a {
  text-decoration: none !important;
}

.w-150 {
  width: 80px;
}

.w-300 {
  width: 300px;
}

.w-80 {
  width: 80% !important;
}

.w-5 {
  width: 5% !important;
}

.w-20 {
  width: 20% !important;
}

.w-68 {
  width: 68% !important;
}

.py-11 {
  padding: 11px 20px !important;
}

body > div:not([id]):not([class]) canvas {
  display: none !important;
}

.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-row {
  display: grid;
  gap: 1rem;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: 100px 1fr 1fr 1fr 50px;
}

.grid-row {
  grid-template-columns: 1fr 1fr 1fr 50px;
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 767px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
